import { mapStepProgressToPage } from '@/services/pathToPage';
import { isString, isObject } from '@/utils/checkDataTypes';
import { adminUserAccess, CLIENT_STATUS } from '@/utils/Client';
import {
    LocalStorageStepTab,
    LocalStorageFactory,
    LocalStorageSiga,
} from '@/services/LocalStorage';
import { Notification } from '@/components/Modal/Notification';
import { getRiskAnalysisByCpf } from '@/services/FenixAPI/RiskAnalysis';
import { FENIX_PROCESS_NOT_EXISTS } from '@/services/FenixAPI';
import { UNICO_PROCESS_STATUS } from '@/constants/acesso-bio.constants';
import { Console } from '@/utils/Console';
import { requestEPC } from '@/services/Request/consultaEntidade';
import {
    simulation_prospection_client,
    simulation_fetch_siga_prospection,
    simulation_dcc_ultima_analise,
} from '@/services/Request/simulation';
import { decodeTokenAPI } from '@/services/Controllers/Token';

export default {
    namespace: 'step_progress_constrol',
    state: {
        canContinue: false,
        canFinish: true,
        canReturn: false,
        canContinueVerificationFraud: false,
        hasSimulation: false,
        currentStepProgress: 0,
        currentStepProgressChild: 0,
        currentStepProgressGrandson: 0,
        formRef: null,
        stepsGrandsonLength: 0,
        executeEffectNextStep: false,
        canContinueNextStep: false,
        isReprovedFromCreditProtection: false,
        didEpcRequestFailed: false,
        localStorageEntity: null,
    },
    reducers: {
        setLocalStorageEntity(state, { payload: dados }) {
            return {
                ...state,
                localStorageEntity: dados.localStorageEntity,
            };
        },
        setDidEpcRequestFailed(state, { payload: dados }) {
            return {
                ...state,
                didEpcRequestFailed: dados.didEpcRequestFailed,
            };
        },
        setIsReprovedFromCreditProtection(state, { payload: dados }) {
            return {
                ...state,
                isReprovedFromCreditProtection: dados.isReprovedFromCreditProtection,
            };
        },
        setExecuteEffectNextStep(state, { payload: dados }) {
            return {
                ...state,
                executeEffectNextStep: dados.executeEffectNextStep,
            };
        },
        setCanContinueNextStep(state, { payload: dados }) {
            return {
                ...state,
                canContinueNextStep: dados.canContinueNextStep,
            };
        },
        setCanContinue(state, { payload: dados }) {
            return {
                ...state,
                canContinue: dados.canContinue,
            };
        },
        setCanContinueVerificationFraud(state, { payload: dados }) {
            return {
                ...state,
                canContinueVerificationFraud: dados.canContinueVerificationFraud,
            };
        },
        setStepsGrandsonLength(state, { payload: dados }) {
            return {
                ...state,
                stepsGrandsonLength: dados,
            };
        },
        setStateHasSimulation(state, { payload: hasSimulation }) {
            return {
                ...state,
                hasSimulation,
            };
        },
        setCanReturn(state, { payload: canReturn }) {
            return {
                ...state,
                canReturn,
            };
        },
        setCanFinish(state, { payload: canFinish }) {
            return {
                ...state,
                canFinish,
            };
        },
        setCurrentStepProgress(state, { payload: dados }) {
            return {
                ...state,
                ...dados,
            };
        },
        setForm(state, { payload: dados }) {
            return {
                ...state,
                ...dados,
            };
        },
    },
    effects: {
        *submit(_, { put, select }) {
            const state = yield select(s => s.step_progress_constrol);
            let newPayload = { ...state };
            const flag = state.formRef.handleSubmit();
            if (flag instanceof Promise) {
                try {
                    const promiseResolved = yield Promise.resolve(flag);
                    if (promiseResolved) {
                        newPayload = _.payload;
                        yield put({
                            type: 'saveCurrentPage',
                            payload: newPayload,
                        });
                    }
                } catch (error) {
                    Console(error);
                }
            } else if (flag) {
                newPayload = _.payload;
                yield put({
                    type: 'saveCurrentPage',
                    payload: newPayload,
                });
            }
        },
        *resetVerificationFraud(_, { put }) {
            yield put({
                type: 'setCanContinueVerificationFraud',
                payload: {
                    canContinueVerificationFraud: false,
                },
            });
        },

        *handleCreditProtectionNextStep(payload, { put, select }) {
            let epcResponse;
            let dccUltimaAnaliseResponse;
            const state = yield select(s => s.step_progress_constrol);

            yield put({
                type: 'setExecuteEffectNextStep',
                payload: {
                    executeEffectNextStep: false,
                },
            });

            const { pessoa } = payload.payload;

            try {
                yield simulation_prospection_client({
                    cpf: pessoa.cnpj_cpf,
                    nome: pessoa.nome,
                    data_nascimento: pessoa.datacriacao,
                    renda_liquida: parseFloat(pessoa.rendaLiquida),
                });

                if (!pessoa.idcnpj_cpf) {
                    Notification(
                        PROJECT_MESSAGE.SUCCESS_TYPE_MESSAGE,
                        PROJECT_MESSAGE.SUCCESS_TITLE_DATA_SAVED,
                        PROJECT_MESSAGE.SUCCESS_MSG_DATA_SAVED,
                    );
                }

                const response = yield simulation_fetch_siga_prospection({
                    cnpj_cpf: pessoa.cnpj_cpf,
                });

                const envelopSimulationPerson = LocalStorageSiga.envelopePersonToLocal(
                    response.data.data,
                );

                LocalStorageSiga.setPersonLocalStorage(state.localStorageEntity, {
                    ...envelopSimulationPerson,
                });

                const params = {
                    cnpj_cpf: pessoa.cnpj_cpf,
                    idFilialUsuario: decodeTokenAPI().usuario_siga?.idfilial,
                    valorPedido: 0,
                    datacriacao: pessoa.datacriacao,
                    occupationType: pessoa.idtipoocupacao ?? 99,
                };

                dccUltimaAnaliseResponse = yield simulation_dcc_ultima_analise({
                    idfilial: decodeTokenAPI().usuario_siga?.idfilial,
                    idcnpj_cpf: `1000${pessoa.cnpj_cpf}`,
                });

                epcResponse = yield requestEPC(params);
            } catch (error) {
                console.error(error);
                Notification('info', 'NÃO FOI POSSÍVEL AVANÇAR', 'Por favor, tente novamente');

                yield put({
                    type: 'setDidEpcRequestFailed',
                    payload: {
                        didEpcRequestFailed: true,
                    },
                });

                return;
            }

            const dadosAnaliseCredito = isObject(dccUltimaAnaliseResponse.data)
                ? dccUltimaAnaliseResponse.data
                : null;

            if (
                isString(dadosAnaliseCredito.pendente_outra_filial) &&
                dadosAnaliseCredito.pendente_outra_filial === '1' &&
                !adminUserAccess()
            ) {
                Notification(
                    PROJECT_MESSAGE.INFO_TYPE_MESSAGE,
                    PROJECT_MESSAGE.ERROR_MSG_NO_ANALYS,
                    PROJECT_MESSAGE.INFO_PREFIX_REASON + dadosAnaliseCredito.situacao_outra_filial,
                );
                yield put({
                    type: 'setCurrentStepProgress',
                    payload: {
                        currentStepProgress: 1,
                        currentStepProgressChild: 0,
                    },
                });
                return;
            }

            if (isString(dadosAnaliseCredito.critica) && dadosAnaliseCredito.critica.length > 0) {
                Notification(
                    PROJECT_MESSAGE.INFO_TYPE_MESSAGE,
                    PROJECT_MESSAGE.INFO_TITLE_OBS_MATRIX,
                    PROJECT_MESSAGE.INFO_PREFIX_REASON + dadosAnaliseCredito.critica,
                );
                yield put({
                    type: 'setCurrentStepProgress',
                    payload: {
                        currentStepProgress: 1,
                        currentStepProgressChild: 0,
                    },
                });
                return;
            }

            if (epcResponse.status !== 200) {
                Notification('info', 'NÃO FOI POSSÍVEL AVANÇAR', 'Por favor, tente novamente');

                yield put({
                    type: 'setDidEpcRequestFailed',
                    payload: {
                        didEpcRequestFailed: true,
                    },
                });

                return;
            }

            if (!epcResponse.data.epcApproved) {
                yield put({
                    type: 'setIsReprovedFromCreditProtection',
                    payload: {
                        isReprovedFromCreditProtection: true,
                    },
                });

                return;
            }

            yield put({
                type: 'setCanContinueNextStep',
                payload: {
                    canContinueNextStep: true,
                },
            });
        },

        *verificationFraud(payload, { put, select }) {
            const { pessoa } = payload.payload;
            const state = yield select(s => s.step_progress_constrol);
            const { cnpj_cpf } = state.formRef.dados ? state.formRef.dados : pessoa;
            const errorsStatus = [2, 4, 5, 9999];
            const data = yield getRiskAnalysisByCpf(cnpj_cpf);

            if (data?.isAllowed || data?.code === FENIX_PROCESS_NOT_EXISTS) {
                yield put({
                    type: 'setCanContinueVerificationFraud',
                    payload: {
                        canContinueVerificationFraud: true,
                    },
                });
            } else if (
                errorsStatus.find(x => x === data?.status) ||
                (!data?.isAllowed && data?.status === UNICO_PROCESS_STATUS.CONCLUIDO)
            ) {
                const messageError = data?.message ? ` (${data.message}).` : '';
                Notification(
                    PROJECT_MESSAGE.ERROR_TYPE_MESSAGE,
                    PROJECT_MESSAGE.ERROR_TITLE,
                    PROJECT_MESSAGE.ERROR_MSG_DOC_VERIFICATION + messageError,
                );
                yield put({
                    type: 'setCurrentStepProgress',
                    payload: {
                        currentStepProgress: 1,
                        currentStepProgressChild: 2,
                        canContinueVerificationFraud: false,
                    },
                });
            }
        },

        *saveCurrentPage(payload, { put, select }) {
            const state = yield select(s => s.step_progress_constrol);
            const {
                currentStepProgress,
                currentStepProgressChild,
                currentStepProgressGrandson,
                hasSimulation,
            } = state;
            const newCanContinue = false;
            const newCurrentStepProgress =
                payload.payload.currentStepProgress !== undefined
                    ? payload.payload.currentStepProgress
                    : currentStepProgress;
            const newCurrentStepProgressChild =
                payload.payload.currentStepProgressChild !== undefined
                    ? payload.payload.currentStepProgressChild
                    : currentStepProgressChild;
            const newCurrentStepProgressGrandson =
                payload.payload.currentStepProgressGrandson !== undefined
                    ? payload.payload.currentStepProgressGrandson
                    : currentStepProgressGrandson;
            const localStorageData = LocalStorageFactory.getMainLocalStorage(hasSimulation);

            LocalStorageStepTab.setLocalStorageStepTab(
                localStorageData,
                mapStepProgressToPage(
                    newCurrentStepProgress,
                    newCurrentStepProgressChild,
                    newCurrentStepProgressGrandson,
                ),
            );
            yield put({
                type: 'setCurrentStepProgress',
                payload: {
                    currentStepProgress: newCurrentStepProgress,
                    currentStepProgressChild: newCurrentStepProgressChild,
                    currentStepProgressGrandson: newCurrentStepProgressGrandson,
                    canContinue: newCanContinue,
                },
            });
        },
        *save(_, { select }) {
            const state = yield select(s => s.step_progress_constrol);
            state.formRef.handleSubmit();
        },
        *offer(_, { select }) {
            const state = yield select(s => s.step_progress_constrol);
            state.formRef.offerSubmit();
        },
        *awaitForCustomerReview(_, { select }) {
            const state = yield select(s => s.step_progress_constrol);
            state.formRef.awaitForCustomerReview();
        },
        *setHasSimulation(payload, { put }) {
            yield put({
                type: 'setStateHasSimulation',
                payload: payload.payload,
            });
        },
    },
};
